import {Box, Container} from "@mui/material";
import {DataGridPro, GridColDef} from "@mui/x-data-grid-pro";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import Page from "../common/Page";
import {
    Authorization,
    CreateUserDto,
    RoleEnum,
    UpdateUserDto,
    useCreateUserMutation,
    useGetUsersQuery,
    useRemoveUserMutation,
    useUpdateUserMutation
} from "../generated/graphql";
import ConfirmButton from "../component/ConfirmButton";
import {InputType} from "../common/FormRenderer";
import UpdatePopupButton from "../component/UpdatePopupButton";
import {useCallback, useContext} from "react";
import NotificationPopup from "../common/NotificationPopup";
import {find, omit} from "lodash";
import {DataGridLocale, hasAuthorization, hasRole, useCompanies} from "../common/Utils";
import {useUser} from "../auth/Auth";
import {CompanyContext} from "../routes";
import {AuthorizationMap} from "../common/Constant";

const companiesMap = (company) => {
    return {value: company?.id, label: company?.name}
}

const UserPage = () => {
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [removeUser] = useRemoveUserMutation();
    const companies = useCompanies();
    const company = useContext(CompanyContext);
    const me = useUser();
    const getCallback = (mutation) => {
        return async (user, {resetForm}, setOpen) => {
            try {
                await mutation(user);
                NotificationPopup.success(`新增成功`);
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        }
    }
    const onRemove = (id) => (
        removeUser({
            variables: {
                id: id,
            },
            refetchQueries: ["getUsers"]
        })
    );
    const onCreate = useCallback(
        getCallback((user) => {
            user = {...user,
                roles: [...(user.admin ? [RoleEnum.Admin] : []), ...(user.manager ? [RoleEnum.Manager] : [])],
                authorizations: [
                    ...(user.laborContract ? [Authorization.LaborContract] : []),
                    ...(user.personnel ? [Authorization.Personnel] : []),
                    ...(user.finance ? [Authorization.Finance] : []),
                    ...(user.cloudInvoice ? [Authorization.CloudInvoice] : []),
                ],
            }
            return createUser({
                variables: {
                    user: omit(user as CreateUserDto, "id", "admin", "manager", "laborContract", "personnel", "finance", "cloudInvoice"),
                },
                refetchQueries: ["getUsers"],
            })
        }),
        [createUser],
    )
    const onUpdate = useCallback(
        getCallback((user) => {
            const roles = hasRole(me, [RoleEnum.Admin]) ? {roles: [...(user.admin ? [RoleEnum.Admin] : []), ...(user.manager ? [RoleEnum.Manager] : [])]} : {};
            user = {...user,
                ...roles,
                authorizations: [...(user.laborContract ? [Authorization.LaborContract] : []),
                    ...(user.personnel ? [Authorization.Personnel] : []),
                    ...(user.finance ? [Authorization.Finance] : []),
                    ...(user.cloudInvoice ? [Authorization.CloudInvoice] : []),],
            }
            return updateUser({
                variables: {
                    user: omit(user, ["id", "admin", "manager", "laborContract", "personnel", "finance", "cloudInvoice"]) as UpdateUserDto,
                    id: user.id,
                },
                refetchQueries: ["getUsers"],
            })
        }),
        [updateUser, me],
    );
    const authColumns = company?.companyAuthorizations?.map((companyAuthorization)=>{
        return {
            name: companyAuthorization.authorization,
            label: AuthorizationMap[companyAuthorization.authorization],
            type: companyAuthorization.enabled ? InputType.checkbox : InputType.hidden,
            value: find(me?.authorizations, (authorization)=>authorization==companyAuthorization.authorization),
        }
    }) ?? []
    const mutationColumns = (params?: any) => {
        return [...[
            {
                name: "id",
                label: "ID",
                value: params?.row.id,
                type: InputType.hidden,
            },
            {
                name: "name",
                label: "姓名",
                value: params?.row.name,
            },
            {
                name: "email",
                label: "Email",
                value: params?.row.email,
            },
        ], ...(hasRole(me, [RoleEnum.Admin]) ? [
                {
                    name: "companies",
                    label: "公司",
                    type: InputType.select,
                    options: companies?.map(companiesMap),
                    value: params?.row.companies ? params?.row.companies.map((company) => {
                        return company.id
                    }) : [],
                    isMulti: true,
                },
                {
                    name: "admin",
                    label: "系統管理者",
                    type: InputType.checkbox,
                    value: params?.row.roles ? hasRole(params?.row, [RoleEnum.Admin]) : false,
                },
                {
                    name: "manager",
                    label: "公司管理員",
                    type: InputType.checkbox,
                    value: params?.row.roles ? hasRole(params?.row, [RoleEnum.Manager]) : false,
                },
                {
                    name: "laborContract",
                    label: "勞報單系統",
                    type: InputType.checkbox,
                    value: params?.row.authorizations ? hasAuthorization(params?.row, [Authorization.LaborContract]) : false,
                },
                {
                    name: "finance",
                    label: "財務報表",
                    type: InputType.checkbox,
                    value: params?.row.authorizations ? hasAuthorization(params?.row, [Authorization.Finance]) : false,
                },
                {
                    name: "personnel",
                    label: "人事系統",
                    type: InputType.checkbox,
                    value: params?.row.authorizations ? hasAuthorization(params?.row, [Authorization.Personnel]) : false,
                },
                {
                    name: "cloudInvoice",
                    label: "電子發票系統",
                    type: InputType.checkbox,
                    value: params?.row.authorizations ? hasAuthorization(params?.row, [Authorization.CloudInvoice]) : false,
                },
            ] : [{ name: "companies", label: "公司", type: InputType.hidden, value: company?.id }, ...authColumns ]
        )]
    }
    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 100},
        {field: "name", headerName: "姓名", width: 150},
        {field: "email", headerName: "Email", width: 250,},
        {
            field: "companies", cellClassName: "format-cell", headerName: "所屬公司", width: 500,
            renderCell: (params) => {
                return <>
                    <div>{params.value.map(item => `${item.name}`).join('、')}</div>
                </>
            },
        },
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) => {
                return <>
                    <UpdatePopupButton
                        title={"修改"}
                        columns={mutationColumns(params)}
                        onSubmit={onUpdate}
                        submitTitle={"儲存"}
                    />&nbsp;
                    <ConfirmButton onConfirm={() => {
                        onRemove(params.row.id)
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ];

    const {loading, data, error} = useGetUsersQuery();
    return (
        <Page title={"user page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <UpdatePopupButton
                    title={"新增使用者"}
                    columns={mutationColumns()}
                    onSubmit={onCreate}
                    submitTitle={"儲存"}
                />
                <DefaultQueryHandler error={error} loading={loading}>
                    <Box
                        width={"100%"}
                        style={{marginTop: "30px", height: "100vh"}}
                    >
                        <DataGridPro
                            localeText={DataGridLocale}
                            autoHeight={true}
                            loading={loading}
                            rows={data?.users || []}
                            columns={columns}
                        />
                    </Box>
                </DefaultQueryHandler>
            </Container>
        </Page>
    );
};
export default UserPage