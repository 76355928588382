import React, {useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, TextField,
    Typography
} from "@mui/material";
import theme from "../theme/Theme";
import {EditTextField} from "../employee/EmployeeDetailButton";
import {HasRole} from "../common/Utils";
import {Authorization, RoleEnum, useUpdateCompanyMutation} from "../generated/graphql";
import {pick, reduce} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {AuthorizationMap} from "../common/Constant";
import {format} from "date-fns";


const UpdateCompanyButton = ({company}) => {
    const [open, setOpen] = useState(false);
    const [editCompany, setEditCompany] = useState(company ?? {});
    const [editAuthorizationMap, setEditAuthorizationMap] = useState(
        reduce(company?.companyAuthorizations, (a, auth) =>
        {
            return {...a, [auth.authorization]: auth}
        }, {}));
    const [updateCompany] = useUpdateCompanyMutation();
    const AuthList = [Authorization.LaborContract, Authorization.Finance, Authorization.Personnel, Authorization.CloudInvoice];
    const onUpdate = async () =>
    {
        const companyAuthorizations = reduce(AuthList, (a:any, auth)=>{
            return (auth in editAuthorizationMap) ? [...a, {...editAuthorizationMap[auth], authorization: auth}] : a;
        }, []);
        // const companyAuthorizations = AuthList.map((auth)=>{
        //     return auth in editAuthorizationMap ? {...editAuthorizationMap[auth], authorization: auth} : null;
        // });
        try {
            await updateCompany({
                variables: {
                    company: {...pick(editCompany, ["name", "taxId"]), companyAuthorizations},
                    id: editCompany.id,
                },
                refetchQueries: ["getCompanies"],
            })
            NotificationPopup.success(`修改完成`);
        } catch (e: any) {
            NotificationPopup.error(e.message);
        } finally {
            setOpen(false);
        }
    };

    const onChange = (fieldName) =>
    {
        return (value) =>
        {
            setEditCompany({...editCompany, [fieldName]: value});
        }
    };
    const updateAuth = (authorization, field, value) =>
    {
        setEditAuthorizationMap({
            ...editAuthorizationMap,
            [authorization]: {...editAuthorizationMap[authorization], [field]: value}
        })
    }

    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h4" align="center">
                    修改
                </Typography>
            </DialogTitle>
            <DialogContent style={{padding: 20}}>
                <EditTextField required type={"name"} label={"名稱"}
                               setter={onChange("name")}
                               value={editCompany?.name}/>
                <br/><br/>
                <EditTextField required type={"taxId"} label={"統一編號"}
                               setter={onChange("taxId")}
                               value={editCompany?.taxId}/>
                <br/><br/>
                <HasRole roles={[RoleEnum.Admin]}>
                    {AuthList.map((auth) =>
                    {
                        const companyAuth = editAuthorizationMap[auth];
                        return <div style={{margin: 10}}>
                            <FormControlLabel control={<Checkbox onChange={({target}) =>
                            {
                                updateAuth(auth, "enabled", target.checked);
                            }} checked={companyAuth?.enabled}/>} label={AuthorizationMap[auth]}/>
                            <div>
                                <TextField InputProps={{startAdornment: <></>}} disabled={!companyAuth?.enabled}
                                           label={"起始日期"} type={"date"}
                                           onChange={({target})=>{updateAuth(auth, "startDate", target.value)}}
                                           value={companyAuth?.startDate ? format(new Date(companyAuth?.startDate), "yyyy-MM-dd") : null}/>
                                <TextField InputProps={{startAdornment: <></>}} disabled={!companyAuth?.enabled}
                                           label={"結束日期"} type={"date"} style={{marginLeft: 20, marginRight: 20}}
                                           onChange={({target})=>{updateAuth(auth, "endDate", target.value)}}
                                           value={companyAuth?.endDate ? format(new Date(companyAuth?.endDate), "yyyy-MM-dd") : null}/>
                                <TextField InputProps={{startAdornment: <></>}} disabled={!companyAuth?.enabled}
                                           label={"提醒日期"} type={"date"}
                                           onChange={({target})=>{updateAuth(auth, "remindDate", target.value)}}
                                           value={companyAuth?.remindDate ? format(new Date(companyAuth?.remindDate), "yyyy-MM-dd") : null}/>
                            </div>
                        </div>
                    })}
                </HasRole>
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    onClick={async () =>
                    {
                        await onUpdate();
                        setOpen(false);
                    }}
                >
                    修改
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() =>
                    {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            修改
        </Button>
    </div>
}
export default UpdateCompanyButton