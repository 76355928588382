import {
    Authorization,
    DayTypeEnum, EmployStateEnum,
    IncomeTypeEnum,
    LaborContractStatusEnum,
    PaymentMethodEnum, SalaryItemTypeEnum,
    SalaryStandardEnum,
    TaxClassificationEnum, WorkTypeEnum
} from "../generated/graphql";

export const LaborContractStatusMap:Record<LaborContractStatusEnum,string> = {
    draft: '草稿',
    unsigned: '待簽名',
    signed: '已簽名',
    finished: '已完成',
    expired: '已失效',
}


export const TaxClassificationMap:Record<TaxClassificationEnum,string> = {
    citizen: "本國籍",
    residentAlien: "外國籍在台滿183天",
    nonResidentAlien: "外國籍在台未滿183天"
}

export const NoInsuranceMap:Record<"yes"|"no",string> = {
    no:"否：未在職業工會投保健保、非專技人員自行職業可免扣執行業務所得之二代健保",
    yes:"是：已在職業工會投保健保、專技人員自行職業可免扣執行業務所得之二代健保"
}

export const IncomeTypeMap:Record<IncomeTypeEnum,string> = {
    a_50: "50薪資所得",
    a_92: "92其他",
    a_9a: "9A執行業務所得",
    a_9b: "9B稿費"
};

export const PaymentMethodMap:Record<PaymentMethodEnum,string> = {
    cash: "現金",
    check: "支票",
    coupon: "禮卷",
    transfer: "匯款"
}

export const SalaryStandardMap:Record<SalaryStandardEnum,string> = {
    all: '全薪',
    half: '半薪',
    none: '不支薪',
    other: '其他',
}

export const DayTypeMap:Record<DayTypeEnum, string> = {
    holiday: '例假日',
    officialHoliday: '國定假日',
    restday: '休息日',
    workday: '工作日',
}

export const WorkTypeMap:Record<WorkTypeEnum, string> = {
    hourly: '時薪',
    monthly: '月薪',
}

export const SalaryItemTypeMap:Record<SalaryItemTypeEnum, string> = {
    normal: '一般',
    plus: '加項',
    minus: '減項',
    company: '公司負擔',
}

export const AuthorizationMap:Record<Authorization, string> = {
    laborContract: '勞報單模組',
    finance: '財務報表模組',
    personnel: '人事管理模組',
    cloudInvoice: '電子發票模組',
}

export const mapToList = (map) => {
    return Object.keys(map).map((key)=>({value: key, label: map[key]}))
}

export const EmployStateMap:Record<EmployStateEnum, string> = {
    employed: '在職中',
    pending: '留職停薪',
    resigned: '已離職',
}
